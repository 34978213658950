import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";


const LoginRedirect = () => {


  const text = "Loading...";
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => history.push("/"), 2000);
  });

  // useEffect(() => {
  //   // Successfully logged with the provider
  //   // Now logging with strapi by using the access_token (given by the provider) in props.location.search
  //   fetch(`${backendUrl}/auth/google/callback${location.search}`)
  //     .then((res) => {
  //       if (res.status !== 200) {
  //         throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
  //       }
  //       return res;
        
  //     })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // Successfully logged with Strapi
  //       // Now saving the jwt to use it for future authenticated requests to Strapi
  //       localStorage.setItem("jwt", res.jwt);
  //       localStorage.setItem("user", encryptText(JSON.stringify(res.user),res.jwt));

  //       setText(
  //         "You have been successfully logged in. You will be redirected in few seconds..."
  //       );
  //       setTimeout(() => history.push("/"), 2000); // Redirect to homepage after 3 sec
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setText("An error occurred, please try again after some time.");
  //       setTimeout(() => history.push("/"), 2000);
  //     });
  // }, [history, location.search, params.providerName]);

  return (
    <Result
      icon={<SmileOutlined />}
      title={text}
    />
  );
};

export default LoginRedirect;
