import React, { useEffect, useState } from "react";

import CardList from "../components/CardList";
import AppHeader from "../components/AppHeader";
import { Layout } from "antd";
import Loading from "./Loading";
import { supabase } from '../Utils/SupabaseClient';
function Home(props) {
  const [searchString, setsearchString] = useState("");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  
  // if (error) return `Error! ${error.message}`;
  const { Content } = Layout;
  if (!localStorage.getItem("view")) {
    localStorage.setItem("view", "viewDecrypt");
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <AppHeader
        searchString={searchString}
        setsearchString={setsearchString}
        refetch={fetchData}
        loading={loading}
      />
      <Content
        style={{
          padding: "0 10px",
          marginTop: 10,
          height: "100%",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <CardList
            data={data}
            refetch={fetchData}
            searchString={searchString}
            setsearchString={setsearchString}
          />
        )}
      </Content>
    </div>
  );

  async function fetchData() {
    setLoading(true);
    const { data, error } = await supabase
      .from('notes')
      .select();

    if (error) {
      console.error('Error fetching data:', error);
    } else {
      setData(data);
    }
    setLoading(false);
  }
}

export default Home;