import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://vzjsvtytmwtjveoozozm.supabase.co'
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZ6anN2dHl0bXd0anZlb296b3ptIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQzNDk0MzMsImV4cCI6MjAzOTkyNTQzM30.Qgkztz0_Y1HTiOtHpf9HpNWLBwMURVJAd4lWMTQIf0Q"

export const supabase = createClient(supabaseUrl, supabaseKey)

// Register this immediately after calling createClient!
// Because signInWithOAuth causes a redirect, you need to fetch the
// provider tokens from the callback.
supabase.auth.onAuthStateChange((event, session) => {
    if (session && session.provider_token) {
      window.localStorage.setItem('oauth_provider_token', session.provider_token)
    }
  
    if (session && session.provider_refresh_token) {
      window.localStorage.setItem('oauth_provider_refresh_token', session.provider_refresh_token)
    }
  
    if (event === 'SIGNED_OUT') {
      window.localStorage.removeItem('oauth_provider_token')
      window.localStorage.removeItem('oauth_provider_refresh_token')
    }
  })
