import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";


const Root = () => {
  return (
    <App />
  );
};

render(<Root />, document.getElementById("root"));

reportWebVitals();
