import React, { useState } from "react";
import { Card, Typography, Input, Button, message, Popconfirm, Space } from "antd";
import { Row, Col } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { AES, enc } from "crypto-js";
import { copyTextToClipboard } from "../Utils/CopyClip";
import { supabase } from "../Utils/SupabaseClient";

const decryptText = (text) => {
  const authenticatedDetails = JSON.parse(localStorage.getItem("sb-vzjsvtytmwtjveoozozm-auth-token"));
  const user = authenticatedDetails['user'];
  var bytes = AES.decrypt(text, user.email);
  var originalText = bytes.toString(enc.Utf8);
  return originalText;
  // return text;
};
// const encryptText = (text) => {
//   return AES.encrypt(text, "SECRET_TOKEN").toString();
// };

export function ClipCard({ data, refetch }) {
  const viewMode = localStorage.getItem("view");
  const { Paragraph } = Typography;
  const { TextArea } = Input;

  const encryptedData = data.data;
 
  const [dataToDisplay, setDataToDisplay] = useState(encryptedData);
  const [editable, setEditable] = useState(false);
  const [saveButtonLoading, setsaveButtonLoading] = useState(false);
  const [deleteButtonLoading, setdeleteButtonLoading] = useState(false);

  const encryptText = (text) => {
    const authenticatedDetails = JSON.parse(localStorage.getItem("sb-vzjsvtytmwtjveoozozm-auth-token"));
    const user = authenticatedDetails['user'];
    return AES.encrypt(text, user.email).toString();
    // return text;
  };
  const handleClick = (e) => {};
  const copySuccess = () => {
    message.success("Copied to Clipboard!", 1);
  };

  const copyError = () => {
    message.error("Unable to Copy, Please check Clipboard permissions", 1);
  };
  const handleMouseOver = (e) => {
    if (!editable) {
      
      setDataToDisplay(decryptText(encryptedData));
    }
  };
  const handleMouseOut = (e) => {
    if (!editable) {
      
      setDataToDisplay(encryptedData);
    }
  };
  const onTextAreaValueChange = ({ target: { value } }) => {
    setDataToDisplay(value);
  };
  return (
    <Card
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      hoverable
      bordered={false}
      style={{ margin: "0px" }}
    >
      {/* <Card */}
      {/*   onClick={handleClick} */}
      {/*   hoverable */}
      {/*   bordered={false} */}
      {/*   style={{ margin: "0px" }} */}
      {/* > */}
      {editable ? (
        <TextArea
          allowClear
          value={dataToDisplay}
          bordered={false}
          autoSize={{ minRows: 1, maxRows: 15 }}
          onChange={onTextAreaValueChange}
        />
      ) : (
        // <TextArea  allowClear onChange={onChange} />
        <Paragraph>
          {viewMode === "viewDecrypt" ? decryptText(data.data) : dataToDisplay}
        </Paragraph>
      )}

      <Row justify="center">
      <Space>
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon={<CopyOutlined />}
            onClick={(e) => {
              copyTextToClipboard(
                decryptText(encryptedData),
                copySuccess,
                copyError
              );
            }}
          />
        </Col>

        {editable ? (
          <>
            <Col>
              <Button
                type="default"
                shape="circle"
                icon={<SaveOutlined />}
                loading={saveButtonLoading}
                onClick={async (e) => {
                  setsaveButtonLoading(true);
                  await supabase.from('notes').update({data: encryptText(dataToDisplay)}).eq('id', data.id);
                  refetch();
                  setsaveButtonLoading(false);
                }}
              />
            </Col>
            <Col>
              <Button
                type="default"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={(e) => {
                  setDataToDisplay(decryptText(encryptedData));
                  setEditable(!editable);
                }}
              />
            </Col>
          </>
        ) : (
          <Col>
            <Button
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              onClick={(e) => {
                setEditable(!editable);
              }}
            />
          </Col>
        )}

        <Col span={4}>
          <Popconfirm
            title="Are you sure want to delete this Clip?"
            onConfirm={async (e) => {
              setdeleteButtonLoading(true);
              await supabase.from('notes').delete().eq('id', data.id);
              refetch();
              setdeleteButtonLoading(false);
            }}
            onCancel={(e) => {
              message.warning("Deletion aborted!");
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="danger"
              shape="circle"
              loading={deleteButtonLoading}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
          </Col>
        </Space>
      </Row>
      {/* <Text type="secondary" style={{ fontSize: 10 }}>
        Last Updated: {updated_at}
      </Text> */}
    </Card>
  );
}
